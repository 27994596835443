import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import Axios from '../..//services/Api.js';
import app_params from "../../config/params";

import {
	searchTypeOptions,
	booleanOptions,
	sortOptions,
} from '../../data/formOptions';

import DropDownInput from '../../components/DropDownInput/DropDownInput';

import styles from './AdvancedSearchScreen.module.css';

const AdvancedSearchScreen = (props) => {
	// const [libraries, setLibraries] = useState([]);
	const [materials, setMaterials] = useState([]);
	const [languages, setLanguages] = useState([]);

	useEffect(() => {
		// Axios.GetBibliotecas()
		// 	.then((res) => {
		// 		if (res.data.result) {
		// 			setLibraries(res.data.data);
		// 		} else {
		// 			setLibraries([]);
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
		// 		setLibraries([]);
		// 	});
		Axios.GetMateriales()
			.then((res) => {
				if (res.data.result) {
					setMaterials(res.data.data);
				} else {
					setMaterials([]);
				}
			})
			.catch((error) => {
				console.log(error);
				setMaterials([]);
			});
		Axios.GetLanguages()
			.then((res) => {
				if (res.data.result) {
					setLanguages(res.data.data);
				} else {
					setLanguages([]);
				}
			})
			.catch((error) => {
				console.log(error);
				setLanguages([]);
			});
		return () => {
			setLanguages([]);
			// setLibraries([]);
			setMaterials([]);
		};
	}, []);

	return (
		<section className='container'>
			<div className='bg-white mx-0 mx-md-3 my-3 shadow-sm rounded'>
				<a href={`${app_params.basepath}`} className={`${styles.div_link}`}>
					<div className='glifos-bg-light-blue text-center p-2'>
						<p>
							¿Desea hacer una búsqueda simple?{' '}
							<u>Volver a inicio</u>
						</p>
					</div>
				</a>
				<div className='py-3 px-4'>
					<h5 className='mb-4'>Búsqueda booleana</h5>
					<form action={`${app_params.basepath}/query`}>
						<input
							type='text'
							value='advanced'
							name='mode'
							hidden
							readOnly
						/>
						<div className='col m-0 p-0'>
							<div
								className={`row m-0 p-0 ${styles.inputRow} mb-1`}>
								<div className='col-12 col-lg-4 offset-lg-1 mt-3 my-lg-3'>
									<DropDownInput
										name='field1'
										options={searchTypeOptions}
									/>
								</div>
								<div className='col-12 col-lg-7 my-3 my-lg-3'>
									<input
										type='text'
										className={`form-control`}
										name='value1'
									/>
								</div>
							</div>
							<div
								className={`row m-0 p-0 ${styles.inputRow} mb-1`}>
								<div className='col-4 col-lg-1 mt-3 my-lg-3'>
									<DropDownInput
										name='op1'
										options={booleanOptions}
									/>
								</div>
								<div className='col-8 col-lg-4 mt-3 my-lg-3'>
									<DropDownInput
										name='field2'
										options={searchTypeOptions}
									/>
								</div>
								<div className='col-12 col-lg-7 my-3 my-lg-3'>
									<input
										type='text'
										className={`form-control`}
										name='value2'
									/>
								</div>
							</div>
							<div
								className={`row m-0 p-0 ${styles.inputRow} mb-1`}>
								<div className='col-4 col-lg-1 mt-3 my-lg-3'>
									<DropDownInput
										name='op2'
										options={booleanOptions}
									/>
								</div>
								<div className='col-8 col-lg-4 mt-3 my-lg-3'>
									<DropDownInput
										name='field3'
										options={searchTypeOptions}
									/>
								</div>
								<div className='col-12 col-lg-7 my-3 my-lg-3'>
									<input
										type='text'
										className={`form-control`}
										name='value3'
									/>
								</div>
							</div>
						</div>
						<hr />
						<div className='row'>
							<div className='col-12 col-lg-6 mb-3 form-group'>
								<label htmlFor='idioma'>Idioma:</label>
								<Select
									isMulti
									name='idioma'
									options={languages?.map((language) => ({
										label: language?.idioma,
										value: language?.codigo,
									}))}
									// defaultValue={{
									// 	label: 'Todas los idiomas',
									// 	value: '',
									// }}
									placeholder='Todas los idiomas'
								/>
							</div>
							<div className='col-12 col-lg-6 mb-3 form-group'>
								<label htmlFor='material'>Material:</label>
								<Select
									isMulti
									name='material'
									options={materials?.map((material) => ({
										label: material?.nombre_material,
										value: material?.nombre_material,
									}))}
									// defaultValue={{
									// 	label: 'Todas los materiales',
									// 	value: '',
									// }}
									placeholder='Todos los materiales'
								/>
							</div>
							{/* <div className='col-12 col-lg-6 mb-3 form-group'>
								<label htmlFor='biblioteca[]'>Biblioteca:</label>
								<Select
									isMulti
									name='biblioteca'
									options={libraries?.map((library) => ({
										label: library?.nombre_biblioteca,
										value: library?.nombre_biblioteca.toLowerCase(),
									}))}
									// defaultValue={{
									// 	label: 'Todas las bibliotecas',
									// 	value: '',
									// }}
									placeholder='Todas las bibliotecas'
								/>
							</div> */}
						</div>
						<hr />
						<div className='row'>
							<div className='col-12 col-lg-6 mb-3 form-group'>
								<label htmlFor='sortby'>Ordenar por:</label>
								<DropDownInput
									name='sortby'
									options={sortOptions}
								/>
							</div>
						</div>
						<hr />
						<div className='row'>
							<div className='col-12 col-lg-4 col-xl-2 offset-lg-8 offset-xl-10'>
								<button className='btn btn-info col-12 text-white'>
									Buscar
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export default AdvancedSearchScreen;
