import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//import Select from 'react-select';

//import { fakeSearchResults } from '../../data/fakeData';

import SearchBar from "../../components/SearchBar/SearchBar";
import SearchFilters from "../../components/SearchFilters/SearchFilters";
import Pagination from "../../components/Pagination/Pagination";
import SearchResultItem from "../../components/SearchResultItem/SearchResultItem";

import LoadingBox from "../../components/LoadingBox/LoadingBox";
import MessageBox from "../../components/MessageBox/MessageBox";
import { simpleSearch } from "../../actions/searchActions";
import app_params from "../../config/params";
import ShareModal from "../../components/ShareModal/ShareModal";
// import Toast from "../../components/Toast/Toast";

const SearchResultsScreen = (props) => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const {
    result: { result_header, records },
    loading,
    error,
  } = useSelector((state) => state.searchResult);

  useEffect(() => {
    const groupParamsByKey = (params) =>
      [...params.entries()].reduce((acc, [key, val]) => {
        if (acc.hasOwnProperty(key)) {
          // if the current key is already an array, we push the value to it
          if (Array.isArray(acc[key])) {
            acc[key] = [...acc[key], val];
          } else {
            // if it's not an array, we will convert it into an array and add the current value to it
            acc[key] = [acc[key], val];
          }
        } else {
          // plain assignment if no special case is present
          acc[key] = val;
        }

        return acc;
      }, {});
    const paramsToObject = (params) => {
      try {
        const urlParams = new URLSearchParams(params);
        const paramsObj = groupParamsByKey(urlParams);
        return paramsObj;
      } catch (e) {
        console.log(e);
        return {};
      }
    };
    const params = paramsToObject(search);
    console.log(params);
    if (Object.keys(params).length > 0) {
      dispatch(simpleSearch({ ...params }));
    }
  }, [search, dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm(e.target.elements);
  };

  const submitForm = (elements) => {
    const searchValue = document.getElementById("searchValue");
    const params = Array.from(elements)
      .concat([searchValue]) // Get all elements in the form
      .filter((item) =>
        ["input", "select"].includes(item.tagName.toLowerCase())
      ) // Filter only input and select elements
      .map((input) => ({ name: input.name, value: input.value })) // Format input values in json
      .filter((item) => item.name !== ""); // Remove blank inputs // Remove duplicate
    console.log(params);

    const paramsResult = params
      .map((param) => `${param.name}=${encodeURIComponent(param.value)}`)
      .join("&");
    console.log(paramsResult);
    window.location.href = `${app_params.basepath}/query?${paramsResult}`;
  };

  const handleSearchBarSubmit = (e) => {
    e.preventDefault();
    const forms = document.getElementsByName("searchForm");
    const windowWidth = window.innerWidth;
    const form = windowWidth > 991 ? forms[0] : forms[1];
    submitForm(form.elements);
  };

  //   const shareResult = () => {
  //     //TODO: Modal to share in different social media pages
  //     navigator.clipboard.writeText(
  //       `${window.location.origin}/opac${pathname}${search}`
  //     );
  //   };

  return (
    <section className="container">
      <div className="row ms-md-1 my-0 my-lg-3 p-0">
        <div className="col-3 d-none d-lg-block">
          <div className="bg-white row py-2 sticky-top shadow-sm rounded">
            <div className="col-12 ">
              <form
                action={`${app_params.basepath}/query`}
                onSubmit={handleSubmit}
                name="searchForm"
              >
                <SearchFilters />
              </form>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-9">
          <div className="row bg-white mx-lg-1 p-2 shadow-sm rounded">
            <div className="col-9 col-xl-11">
              <form onSubmit={handleSearchBarSubmit}>
                <SearchBar />
              </form>
            </div>
            <div className="col-3 col-xl-1">
              {/* <button
                className="btn btn-secondary col-12"
                onClick={() => shareResult()}
                id="buttonShare"
              >
                <span>
                  <i className="fas fa-share-alt"></i>
                </span>
              </button> */}
              <button
                className="btn btn-secondary col-12"
                data-bs-toggle="modal"
                data-bs-target={`#shareSearchModal`}
              >
                <span>
                  <i className="fas fa-share-alt"></i>
                </span>
              </button>
            </div>
          </div>
          <div className="d-lg-none row py-2 sticky-top ">
            <div className="bg-white border shadow-sm rounded col-4">
              <button
                className="col-12 btn btn-sm text-start"
                data-bs-toggle="modal"
                data-bs-target="#searchfiltersmodal"
                onClick={(event) => {
                  event.preventDefault();
                }}
              >
                <span>
                  <i className="fas fa-filter"></i> Filtros
                </span>
              </button>
            </div>
          </div>
          {loading ? (
            <LoadingBox />
          ) : error ? (
            <MessageBox severity="danger">{error}</MessageBox>
          ) : result_header?.total_results === "NaN" ||
            result_header?.total_results === "0" ? (
            <div className="row mx-lg-1 mt-3">
              <div className="col-12 mb-2 p-5 bg-white shadow-sm rounded text-center">
                <h5>No se encontraron resultados.</h5>
              </div>
            </div>
          ) : (
            <div>
              <div className="row px-0 px-lg-3 my-3">
                <Pagination />
              </div>
              {records && Array.isArray(records) && (
                <div className="row mx-lg-1 ">
                  {records?.map((item) => (
                    <SearchResultItem
                      item={item}
                      key={item.id}
                      searchargs={item?.searchargs}
                    />
                  ))}
                </div>
              )}
              {records && !Array.isArray(records) && (
                <div className="row mx-lg-1 ">
                  <SearchResultItem
                    item={records}
                    key={records.id}
                    searchargs={records?.searchargs}
                  />
                </div>
              )}
              <div className="row mx-lg-1 py-2">
                <Pagination />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="modal fade" id="searchfiltersmodal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Filtros
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                action={`${app_params.basepath}/query`}
                onSubmit={handleSubmit}
                name="searchForm"
              >
                <SearchFilters />
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <Toast buttonID="buttonShare" toastID="toastShare" variant="secondary">
        Enlace de búsqueda copiado en portapapeles
      </Toast> */}
      <ShareModal id={`shareSearchModal`} url={`${window.location.origin}${app_params.basepath}${pathname}${search}`} quote={`GLIFOS - library: Búsqueda`} />
    </section>
  );
};

export default SearchResultsScreen;
